/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A program that reads in market data of prices of securities, stores them and does a simple calculation of the change in price over an amount of time given a fixed starting date. You can set intervals of time such as a day, week, month, etc when calculations will be made. The output will be a list of securities ordered by those with the highest change in price over the given time period."), "\n", React.createElement(_components.p, null, "The thought is an assisted way to get a list of securities whose rate of change in price is greater than average and can be provided to an individual who can decide whether to invest or not. A more advanced take on it is having the program figure out price trends that are more “smoothly” changing than others—versus those that are more “spiky”, with the intention that a smoother curve implies a trend which a human could then reason about after researching the security. With the goal of trying to separate erratic securities from more reliable ones."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
